import React from "react";
import {
  AiFillFacebook,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import './SocialMedia.scss';

function SocialMedia() {

  return (
    <>
        <ul>
            <li className="social-icons">
                <a
                href="https://www.instagram.com/kleanleaf"
                target="_blank"
                rel="noreferrer"
                className="icon-colour home-social-icons"
                >
                <AiFillInstagram />
                </a>
            </li>
            <li className="social-icons">
                <a
                href="https://twitter.com/kleanleaf"
                target="_blank"
                rel="noreferrer"
                className="icon-colour  home-social-icons"
                >
                <AiOutlineTwitter />
                </a>
            </li>
            <li className="social-icons">
                <a
                href="https://www.facebook.com/profile.php?id=100092286444117"
                target="_blank"
                rel="noreferrer"
                className="icon-colour  home-social-icons"
                >
                <AiFillFacebook />
                </a>
            </li>
            
        </ul>
   
    </>
  );
}
export default SocialMedia;
