import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import SocialMedia from "../SocialMedia/SocialMedia";
import laundryLine from "../../Assets/laundry-line.png";
import './Contact.scss';

function ContactForm() {
  const [result, setResult] = useState("");
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    setEmailError("");

    const formData = new FormData(event.target);
    const email = formData.get("email");

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      setResult("");
      return;
    }

    formData.append("access_key", process.env.REACT_APP_ACCESS_KEY);

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <Container fluid className="contact-section primary page-top-settings">
      <Container>
        <Row>
          <h1 className="center-text">Laundry Detergent Sheets Manufacturer</h1>
          <h2 className="page-title">
            Contact Us
            <p>We'd love to hear from you. Fill out the form below to get in touch.</p>
          </h2>
        </Row>
        <Row>
          <Col md={6} className="kitty">
            <Tilt>
              <img
                src={laundryLine}
                alt="laundry detergent strips manufacturer"
                className="img-fluid"
              />
            </Tilt>
          </Col>
          <Col md={5} className="contact-right equal-height-cards">
            <form onSubmit={onSubmit} className="contact-form">
              <label>Name *</label>
              <input type="text" name="name" required />

              <label>Email *</label>
              <input
                type="email"
                name="email"
                required
                onChange={(e) => {
                  setEmailError(validateEmail(e.target.value) ? "" : "Please enter a valid email address.");
                }}
                onFocus={() => setEmailError("")}
              />
              {emailError && <span className="error-message">{emailError}</span>}

              <label>Company</label>
              <input type="text" name="company" />

              <label>Message</label>
              <textarea name="message" required maxLength="2000"></textarea>

              <button type="submit" className="submit-button primary-btn">Submit</button>
            </form>
            <span className="form-result">{result}</span>
          </Col>
        </Row>

        <Row className="mt-5">
          <p>We’d love to hear from you! Reach out to us at <strong>hello@kleanleaf.us</strong> or follow us on social media for the latest updates.</p>
          <p>📍 Our Office: Columbus, Ohio</p>
          <p>We’ll get back to you as soon as possible!</p>
          <p>Follow us on:</p>
          <SocialMedia />
        </Row>
      </Container>
    </Container>
  );
}

export default ContactForm;
